import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Channel from 'types/channel.type';
import { TemplateSizeCategory } from 'services/template/template.response';
import { TemplateService } from 'services/template/template.service';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Icon from 'components/ui-components-v2/Icon';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Select from 'components/ui-components-v2/Select';
import { withResources } from 'components/data/Resources';
import Dialog from 'components/ui-components/Dialog';
import LWFiles from 'components/data/Files';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Translation from 'components/data/Translation';
import '../styles/template-size-dialog.scss';
import EditorData from 'components/editor-data/EditorData';
import TemplateHelpers from 'components/data/TemplateHelpers';

type CategoryTypes = 'image' | 'video' | 'audio' | 'lottie' | 'font' | 'rest';
type ChannelTypes = 'Google Ads' | 'Google Campaign Manager';

interface CategorySize {
    category: CategoryTypes;
    tooltip?: string;
    label: string;
    icon: string;
    size: number;
    percentageSize: number;
}

interface Sizes {
    images: number;
    videos: number;
    audios: number;
    lotties: number;
    fonts: number;
    rest: number;
    total: number;
}

interface Props {
    data: any;
    onReceiveSize: (size: number) => void;
    channels: Channel[];
    onClose: () => void;
}

const TemplateSizeDialog = ({ data, onReceiveSize, channels, onClose }: Props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [channel, setChannel] = useState<ChannelTypes>('Google Ads');
    const [sizes, setSizes] = useState<Sizes | null>(null);

    /**
     * Prepare the data and get the size of the template files.
     */
    useEffect(() => {
        (async () => {
            try {
                const requestData = (() => {
                    if (typeof data !== 'object' || data === undefined || data === null || !('template' in data)) {
                        throw new Error();
                    }

                    const language = EditorData.getLanguage();

                    const templateData = data.template;

                    delete data.template;

                    TemplateHelpers.removeGallery(data, language);
                    TemplateHelpers.removeTemplateFormatData(templateData, data.format, data);

                    const newData = {
                        model: {
                            ...data
                        },
                        template: templateData
                    };

                    return newData;
                })();

                if (!requestData) {
                    throw new Error();
                }

                const responseData = await TemplateService.getTemplateSize(requestData);

                if (!responseData) {
                    throw new Error();
                }

                /**
                 * Calculate the size of each category.
                 */
                const imageSize = calculateCategorySize(responseData.images);
                const videoSize = calculateCategorySize(responseData.videos);
                const audioSize = calculateCategorySize(responseData.audios);
                const fontSize = calculateCategorySize(responseData.fonts);
                const lottieSize = calculateCategorySize(responseData.lotties);
                const restSize = calculateCategorySize(responseData.rest);
                const totalSize = imageSize + videoSize + audioSize + fontSize + lottieSize + restSize;
                setSizes({
                    images: imageSize,
                    videos: videoSize,
                    audios: audioSize,
                    lotties: lottieSize,
                    fonts: fontSize,
                    rest: restSize,
                    total: totalSize
                });
                onReceiveSize(totalSize);
            } catch {
                setError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    /**
     * Calculate the total category size.
     */
    const calculateCategorySize = (category: TemplateSizeCategory) => {
        return Object.keys(category).reduce((total, item) => {
            category[item].forEach((category) => {
                total += category.size;
            });

            return total;
        }, 0 as number);
    };

    /**
     * Segment for the bar.
     */
    const Segment = ({
        category,
        size,
        totalCategories,
        index
    }: {
        category: CategorySize['category'];
        size: CategorySize['size'];
        totalCategories: number;
        index: number;
    }) => (
        <div
            className={`preview-frame__template-size-dialog__bar__segment preview-frame__template-size-dialog__bar__segment--${category}`}
            style={{ width: (size > 100 ? 100 : size) + '%', zIndex: totalCategories - index, marginLeft: index !== 0 ? '-3%' : 0 }}
        />
    );

    /**
     * List item.
     */
    const ListItem = ({
        tooltip,
        icon,
        category,
        label,
        size,
        percentageSize
    }: {
        tooltip: CategorySize['tooltip'];
        icon: CategorySize['icon'];
        category: CategorySize['category'];
        label: CategorySize['label'];
        size: CategorySize['size'];
        percentageSize: CategorySize['percentageSize'];
    }) => (
        <div className="preview-frame__template-size-dialog__list-item">
            <div
                className={`preview-frame__template-size-dialog__list-item__icon-container preview-frame__template-size-dialog__list-item__icon-container--${category}`}>
                <Icon className="preview-frame__template-size-dialog__list-item__icon">{icon}</Icon>
            </div>
            <h4 className="preview-frame__template-size-dialog__list-item__category">{label}</h4>
            {tooltip && (
                <Tooltip title={tooltip}>
                    <Icon className="preview-frame__template-size-dialog__list-item__info">info</Icon>
                </Tooltip>
            )}
            <div className="preview-frame__template-size-dialog__list-item__info-container">
                {percentageSize > 90 && (
                    <Tooltip title={Translation.get('general.errors.fileSizeToLarge', 'template-designer', { fileType: label })}>
                        <Icon className="preview-frame__template-size-dialog__list-item__to-large">error</Icon>
                    </Tooltip>
                )}
                <div
                    className={classNames('preview-frame__template-size-dialog__list-item__size', {
                        'preview-frame__template-size-dialog__list-item__size--to-large': percentageSize > 90
                    })}>
                    {LWFiles.humanReadableSize((size / 1000) * 1024)}
                </div>
            </div>
        </div>
    );

    /**
     * Calculate category sizes.
     */
    const categorySizes: CategorySize[] | undefined = (() => {
        if (!sizes) return;

        const maxSize = channels?.[channel]?.size ?? 0;

        const categories: CategorySize[] = [
            {
                category: 'image',
                size: sizes.images,
                percentageSize: (sizes.images / maxSize) * 100,
                label: Translation.get('labels.images', 'common'),
                icon: 'image'
            },
            {
                category: 'video',
                size: sizes.videos,
                percentageSize: (sizes.videos / maxSize) * 100,
                label: Translation.get('labels.videos', 'common'),
                icon: 'movie'
            },
            {
                category: 'audio',
                size: sizes.audios,
                percentageSize: (sizes.audios / maxSize) * 100,
                label: Translation.get('labels.audio', 'common'),
                icon: 'music_note'
            },
            {
                category: 'font',
                size: sizes.fonts,
                percentageSize: (sizes.fonts / maxSize) * 100,
                label: Translation.get('labels.fonts', 'common'),
                icon: 'text_fields'
            },
            {
                category: 'lottie',
                size: sizes.lotties,
                percentageSize: (sizes.lotties / maxSize) * 100,
                label: Translation.get('labels.lotties', 'common'),
                icon: 'animation'
            },
            {
                category: 'rest',
                size: sizes.rest,
                percentageSize: (sizes.rest / maxSize) * 100,
                label: Translation.get('topbar.templateActions.scriptsLabel', 'template-designer'),
                icon: 'code',
                tooltip: Translation.get('topbar.templateActions.scriptsDescription', 'template-designer')
            }
        ];

        // Calculate the total percentage.
        const totalPercentage = categories.reduce((total, item) => {
            total += item.percentageSize;
            return total;
        }, 0);

        /**
         * If the total percentage is higher then 100, scale the percentage size.
         * Otherwise the segments will overflow.
         */
        if (totalPercentage > 100) {
            const scaleFactor = 100 / totalPercentage;

            // Loop over the categories and scale the percentage size with the scaleFactor.
            categories.forEach((item) => {
                item.percentageSize *= scaleFactor;
            });
        }

        /**
         * Add 2% to to each percentageSize that has value of higher then 0.
         * The segments are moved 2% to the left to remove the border radius gap.
         * Otherwise the percentages are not correct.
         */
        categories.forEach((item) => {
            if (item.percentageSize > 0) {
                item.percentageSize += 2;
            }
        });

        return categories.sort((a, b) => b.size - a.size) as CategorySize[];
    })();

    /**
     * Calculate total size.
     */
    const totalSize = ((): number | undefined =>
        categorySizes?.reduce((total, item) => {
            total += item.size;
            return total;
        }, 0))();

    /**
     * Get the format.
     */
    const format = ((): string => {
        if (typeof data !== 'object' || data === undefined || data === null || !('format' in data)) return '';
        return data.format as string;
    })();

    return (
        <Dialog open title={Translation.get('templateSizeDialog.title', 'campaigns', { format })} maxWidth="lg" onClose={onClose}>
            <div className="preview-frame__template-size-dialog">
                {!loading && !error && (
                    <div className="preview-frame__template-size-dialog__select">
                        <div className="preview-frame__template-size-dialog__select__label">
                            {Translation.get('templateSizeDialog.channelLabel', 'campaigns')}
                        </div>
                        <Select
                            className="preview-frame__template-size-dialog__select__container"
                            value={channel}
                            onChange={(event) => {
                                /**
                                 * Set the loading state to true.
                                 * Wait 300ms.
                                 * Set the channel.
                                 * Set the loading state to false.
                                 * Otherwise it changes to fast and the user doesn't see it changing.
                                 */
                                setLoading(true);
                                setTimeout(() => {
                                    setChannel(event.target.value as ChannelTypes);
                                    setLoading(false);
                                }, 300);
                            }}
                            displayEmpty
                            renderValue={(value) => (
                                <div className="preview-frame__template-size-dialog__select__value">
                                    {channels?.[value as string]?.icon && <img src={channels[value as string].icon} alt={`${value} icon`} />}
                                    <div>{value as string}</div>
                                </div>
                            )}>
                            {Object.keys(channels).map((item) => (
                                <MenuItem key={item} className="preview-frame__template-size-dialog__select__value" value={item}>
                                    <img src={channels[item].icon} alt={`${channels[item].label} icon`} />
                                    <div>{channels[item].label}</div>
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                )}

                {!loading && !error && sizes && categorySizes && (
                    <div className="preview-frame__template-size-dialog__overview">
                        <div className="preview-frame__template-size-dialog__info">
                            <h4>{channels?.[channel]?.label ?? 'Channel'}</h4>

                            <div className="preview-frame__template-size-dialog__size">
                                {`${totalSize && LWFiles.humanReadableSize((totalSize / 1000) * 1024)} of ${LWFiles.humanReadableSize(
                                    ((channels?.[channel]?.size ?? 0) / 1000) * 1024
                                )}`}
                            </div>
                        </div>

                        <div className="preview-frame__template-size-dialog__bar">
                            {categorySizes.map((item, index) => (
                                <Segment
                                    key={item.category}
                                    totalCategories={categorySizes.length}
                                    index={index}
                                    category={item.category}
                                    size={item.percentageSize}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {!loading && !error && categorySizes && (
                    <div className="preview-frame__template-size-dialog__list">
                        {categorySizes.map((item) => (
                            <ListItem
                                key={item.category}
                                tooltip={item.tooltip}
                                icon={item.icon}
                                category={item.category}
                                label={item.label}
                                size={item.size}
                                percentageSize={item.percentageSize}
                            />
                        ))}
                    </div>
                )}

                {error && <div className="preview-frame__template-size-dialog__error">{Translation.get('snackbar.errorDataTemplateSize', 'campaigns')}</div>}

                {loading && (
                    <div className="preview-frame__template-size-dialog__loader">
                        <CircularProgress />
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default withResources(TemplateSizeDialog, [{ resource: 'channels' }]);
